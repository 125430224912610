import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from '../home';
import MonstersList from '../monsters/monsters-list';
import Monster from '../monsters/monster';
import AddMonster from '../monsters/monster-form';
import Login from '../login';
import NotFoundPage from '../NotFoundPage';

const Routes = () => (
    <Switch>
        <Redirect exact path="/" to="/monsters" />
        <Route exact path= "/home" component={Home} />
        <Route exact path="/monsters" component={MonstersList} />
        <Route exact path="/monsters/addMonster" component={AddMonster} />
        <Route exact path="/monsters/editMonster" component={AddMonster} />
        <Route exact path="/monsters/id/:id" component={Monster} />
        <Route exact path="/actions" to="/monsters" />
        <Route exact path="/login" component={Login} />
        <Route component={NotFoundPage} />
    </Switch>
);

export default Routes;