import http from "../http-common";

class MonsterDataService {
    async getAll() {
        const response = await http.get('/monsters');
        return response.data.monsters;
    }

    async get(id) {
        const response = await http.get(`/monsters/${id}`);
        return response.data;
    }

    /*find(query, by = "name") {
        return http.get(`?${by}=${query}&page=${page}`);
    }*/

    async createMonster(data) {
        const response = await http.post('/monsters/add', data);
        return;
    }

    updateMonster(data, monsterId) {
        return http.put(`/monsters/${monsterId}`, data);
    }

    deleteMonster(id, userId) {
        return http.delete(`/monsters/${id}`);
    }

}

export default new MonsterDataService();