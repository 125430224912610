import axios from "axios";

//const baseURL = "http://localhost:8000/api/v1"
const baseURL = "https://us-central1-monstermaker-116b4.cloudfunctions.net/monstersApi/api/v1"
//const baseURL =  "/api/v1";
const httpInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "application/json",
    }
});

httpInstance.interceptors.request.use(req => {
  console.log(`request: ${req.method} ${req.url}`);
  // Important: request interceptors **must** return the request.
  return req;
},
error => {
  console.log(`request error: ${error}`)
  return Promise.reject(error);
});

httpInstance.interceptors.response.use(res => {
  // Important: response interceptors **must** return the response.
  return res;
},
error => {
  console.log(`response error: ${error}`)
  return Promise.reject(error);
});

export default httpInstance;