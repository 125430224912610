import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const PRIMARY_COLOR = '#AF2204'; // dark red
const SECONDARY_COLOR = '#2766E4'; // blue
const CONTRAST_COLOR = '#FFFFFF'; // white
const ERROR_COLOR = '#F3E50C'; // yellow

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      constrastText: CONTRAST_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    error: {
        main: ERROR_COLOR,
    },
    warning: {
        main: ERROR_COLOR,
    },
    divider: PRIMARY_COLOR
  },
  spacing: 4,
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSelect: {
      variant: 'outlined',
    },
  }
});

const defaultTheme = responsiveFontSizes(theme);

export default defaultTheme;