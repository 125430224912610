import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardActionArea, CardHeader, CardMedia } from '@material-ui/core';

const Home = () => {
    return (
        <div>
            <Card>
                <CardActionArea component={Link} to={`/monsters`}>
                    <CardHeader
                        title="MONSTERS"
                    />
                    <CardMedia
                        component="img"
                        height="194"
                        image="../../shared/assets/Mad-Ogre2.jpg"
                        alt="monster"
                    />
                </CardActionArea>
            </Card>
            <Card>
                <CardActionArea component={Link} to={`/actions`}>
                    <CardHeader
                        title="ACTIONS"
                    />
                    <CardMedia
                        image="../shared/assets/fist-punching-in-a-vintage-propaganda-christos-georghiou.jpeg"
                    />
                </CardActionArea>
            </Card>
        </div>
    )  
}

export default Home;