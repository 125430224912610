import React from 'react';
import { ListItem, Divider, ListItemText, ListItemIcon } from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/Details';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi'

const ListItems = () => {
  return (
      <div>
        <ListItem
            component="a"
            button
            href="/monsters"
        >
        <ListItemIcon>
            <DetailsIcon />
        </ListItemIcon>
        <ListItemText>
            Monsters
        </ListItemText>
      </ListItem>
      <ListItem
            component="a"
            button
            href="/actions"
        >
        <ListItemIcon>
            <SportsKabaddiIcon />
        </ListItemIcon>
        <ListItemText>
            Actions
        </ListItemText>
      </ListItem>
      <Divider />
      </div>
  );
};

export default ListItems;