import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MonsterDataService from '../../shared/services/monster';
import { makeStyles, useTheme, CardActionArea, Card, CardContent, Typography,
  Divider, Fab, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.secondary
  },
}));

const MonstersList = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [monsters, setMonsters] = useState([]);

  useEffect(() => {
    retrieveMonsters();
  }, []);

  const retrieveMonsters = async () => {
    try {
      const response = await MonsterDataService.getAll();
      setMonsters(response);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMonster = (monsterId, index) => {
    MonsterDataService.deleteMonster(monsterId)
    .then(response => {
      setMonsters(() => {
        const newList = monsters.filter(monster => monster._id !== monsterId);
        setMonsters(newList);
      })
    })
  }

  return (
    <>
      {
        monsters.map((monster, index) => (
          <Card variant="outlined" key={index}>
            <CardActionArea component={Link} to={`/monsters/id/${monster._id}`}>
              <CardContent>
                <Typography variant="h4">
                  {monster.name}
                </Typography>
                <Divider variant="middle" />
                <Typography variant="body2">
                  {monster.description}
                </Typography>
              </CardContent>
            </CardActionArea>
            <Button onClick={() => deleteMonster(monster._id, index)}>
                Delete
              </Button>
          </Card>
        ))
      }
      <div>
        <Fab className={classes.fab} color="secondary" aria-label="add" component={Link} to={`/monsters/addMonster`}>
          <AddIcon />
        </Fab>
      </div>
    </>
  );
};

export default MonstersList;
