import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './components/app';
import Login from './components/login';
import { MuiThemeProvider } from '@material-ui/core';
import { defaultTheme } from './themes';

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={defaultTheme}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route component={App} />
      </Switch>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);


