import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MonsterDataService from '../../shared/services/monster';
import { monsterSizes, monsterTypes, monsterAlignments, savThrows, damages,
  senses, languages, conditions } from './constants';
import { Container, makeStyles, useTheme, Grid, Typography, TextField,
  Button, Select, InputLabel, MenuItem, Divider, capitalize, Dialog,
  DialogTitle, DialogContent, DialogContentText, FormControlLabel, Checkbox,
  DialogActions } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  firstField: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  lastField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  divider: {
    background: theme.palette.divider,
    margin: theme.spacing(2),
  },
  gridContainer: {
    marginTop: theme.spacing(2),
  },
  statsSelects: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  stackedSelectInputs: {
    marginTop: theme.spacing(4)
  },
  button: {
    marginTop: theme.spacing(4)
  }
}));

const AddMonster = props => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  let editing = false;

  let initialMonsterState = {
    name: '',
    description: '',
    size: '',
    type: '',
    alignment: '',
    armorClass: 0,
    hitPoints: 0,
    speed: 0,
    strength: 0,
    dexterity: 0,
    constitution: 0,
    intelligence: 0,
    wisdom: 0,
    charisma: 0,
    proficiencyBonus: 0,
    savingThrows: '',
    damageVulnerabilities: '',
    damageImmunities: '',
    damageResistances: '',
    consitionImmunities: '',
    senses: {
      passivePerceptionText: '',
      passivePerceptionValue: 0,
      trueSight: false,
      trueSightValue: 0,
      blindSight: false,
      blindSightValue: 0,
      darkVision: false,
      darkVisionValue: 0,
    },
    languages: [],
    specialTraits: [],
    actions: [],
  };
  console.log(location.state)

  if (location.state && location.state.currentMonster) {
    editing = true;
    initialMonsterState = props.location.state.currentMonster.data;
  }

  const [openSavingThrows, setOpenSavingThrows] = React.useState(false);
  const [savingThrowsChecks, setSavingThrowsChecks] = React.useState({
    strength: false,
    dexterity: false,
    constitution: false,
    intelligence: false,
    wisdom: false,
    charisma: false
  });
  const [submitted, setSubmitted] = useState(false);
  const [monster, setMonster] = useState(initialMonsterState);

  const { strength, dexterity, constitution, intelligence, wisdom, charisma } = savingThrowsChecks;

  const handleOpenSavingThrows = () => {
    setOpenSavingThrows(true);
  };

  const handleCloseSavingThrows = () => {
    setOpenSavingThrows(false);
    let savingThrowsList = [];
    if (strength) {
      savingThrowsList.push('Strength');
    }
    if (dexterity) {
      savingThrowsList.push('Dexterity');
    }
    if (constitution) {
      savingThrowsList.push('Constitution');
    }
    if (intelligence) {
      savingThrowsList.push('Intelligence');
    }
    if (wisdom) {
      savingThrowsList.push('Wisdom');
    }
    if (charisma) {
      savingThrowsList.push('Charisma');
    }
    monster.savingThrows = savingThrowsList.join(', ')
  };

  const handleSavingThrowsChange = (event) => {
    setSavingThrowsChecks({
      ...savingThrowsChecks,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const newValues = {
      ...monster,
      [event.target.name]: value,
    }
    setMonster(newValues);
  };

  const saveNewMonster = async(event) => { 
    event.preventDefault();
    // add to data when user data added:       
    //user: props.user.name || 'tiffany',
    //user_id: props.user.id || '001',
    monster.name.trim();
    monster.description.trim();

    if (editing) {
      const monsterId = props.location.state.currentMonster._id
      MonsterDataService.updateMonster(monster, monsterId)
        .then(response => {
          setSubmitted(true);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      try {
        const response = await MonsterDataService.createMonster(monster);
        setSubmitted(true);
      } catch(err) {
          console.error(err);
      };
    }
  };
  

  return (
    <Container>
      <Typography variant="h6" align="center">
        Make a Monster
      </Typography>
      {submitted ? (
          <div>
            <Typography>{`You submitted ${monster.name} successfully!`}</Typography>
            <Button component={Link} to={`/monsters`}>
              Return to list
            </Button>
          </div>
        ) : (
          <div>
            <TextField
              className={classes.textField}
              name="name"
              required
              id="monster-name"
              label="Monster Name"
              fullWidth
              type="text"
              autoComplete="off"
              data-lpignore="true"
              data-form-type="text"
              value={monster.name}
              onChange={handleChange}
            />
            <TextField
              className={classes.lastField}
              name="description"
              id="monster-description"
              label="Description"
              fullWidth
              type="text"
              multiline
              maxRows={4}
              value={monster.description}
              onChange={handleChange}
            />
            <Divider color="primary" />
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs>
                <InputLabel shrink id="size-select-label">Size</InputLabel>
                <Select
                  name="size"
                  labelId="monster-size"
                  id="monster-size"
                  value={monster.size}
                  onChange={handleChange}
                  label="Size"
                  fullWidth
                >
                  {monsterSizes.map(size => {
                    return (
                      <MenuItem key={size} value={size}>
                        {capitalize(size)}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item xs>
                  <InputLabel shrink id="type-select-label">Type</InputLabel>
                  <Select
                    name="type"
                    labelId="monster-type"
                    id="monster-type"
                    value={monster.type}
                    onChange={handleChange}
                    label="Type"
                    fullWidth
                  >
                    {monsterTypes.map(type => {
                      return (
                        <MenuItem key={type} value={type}>
                          {capitalize(type)}
                        </MenuItem>
                      )
                    })}
                  </Select>
              </Grid>
              <Grid item xs>
                  <InputLabel shrink id="alignment-select-label">Alignment</InputLabel>
                  <Select
                    name="alignment"
                    labelId="monster-alignment"
                    id="monster-alignment"
                    value={monster.alignment}
                    onChange={handleChange}
                    label="Alignment"
                    fullWidth
                  >
                    {monsterAlignments.map(alignment => {
                      return (
                        <MenuItem key={alignment} value={alignment}>
                          {capitalize(alignment)}
                        </MenuItem>
                      )
                    })}
                  </Select>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextField
                  className={classes.textField}
                  name="armorClass"
                  id="monster-armor-class"
                  label="Armor Class"
                  fullWidth
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                  data-form-type="number"
                  value={monster.armorClass}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.textField}
                  name="hitPoints"
                  id="monster-hit-points"
                  label="Hit Points"
                  fullWidth
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                  data-form-type="number"
                  value={monster.hitPoints}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                    className={classes.lastField}
                    name="speed"
                    required
                    id="monster-speed"
                    label="Speed (Ft)"
                    fullWidth
                    type="number"
                    autoComplete="off"
                    data-lpignore="true"
                    data-form-type="number"
                    value={monster.speed}
                    onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Divider color="primary" />
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                      className={classes.statsSelects}
                      name="strength"
                      id="monster-strength"
                      label="Str"
                      fullWidth
                      type="number"
                      autoComplete="off"
                      data-lpignore="true"
                      data-form-type="number"
                      value={monster.strength}
                      onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.statsSelects}
                  name="dexterity"
                  id="monster-dexterity"
                  label="Dex"
                  fullWidth
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                  data-form-type="number"
                  value={monster.dexterity}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.statsSelects}
                  name="constitution"
                  id="monster-constitution"
                  label="Con"
                  fullWidth
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                  data-form-type="number"
                  value={monster.constitution}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.statsSelects}
                  name="intelligence"
                  id="monster-intelligence"
                  label="Int"
                  fullWidth
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                  data-form-type="number"
                  value={monster.intelligence}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.statsSelects}
                  name="wisdom"
                  id="monster-wisdom"
                  label="Wis"
                  fullWidth
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                  data-form-type="number"
                  value={monster.wisdom}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.statsSelects}
                  name="charisma"
                  id="monster-charisma"
                  label="Char"
                  fullWidth
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                  data-form-type="number"
                  value={monster.charisma}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Divider color="primary" />
            <TextField
              className={classes.firstField}
              name="proficiencyBonus"
              id="proficiency-bonus"
              label="Proficiency Bonus"
              fullWidth
              type="number"
              autoComplete="off"
              data-lpignore="true"
              data-form-type="number"
              value={monster.proficiencyBonus}
              onChange={handleChange}
            />
            <InputLabel shrink id="saving-throws-label">
              Saving Throws
            </InputLabel>
            <TextField
              name="savingThrows"
              labelId="saving-throws"
              id="damage-vulnerabilities"
              value={monster.savingThrows}
              onChange={handleChange}
              fullWidth
              onClick={handleOpenSavingThrows}
            />
            <Dialog open={openSavingThrows} onClose={handleCloseSavingThrows}>
            <DialogTitle>Saving Throws</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Select as many saving throws as you want.
              </DialogContentText>
              <FormControlLabel
                control={
                  <Checkbox
                    name="strength"
                    checked={strength}
                    onChange={handleSavingThrowsChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Strength"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="dexterity"
                    checked={dexterity}
                    onChange={handleSavingThrowsChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Dexterity"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="constitution"
                    checked={constitution}
                    onChange={handleSavingThrowsChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Constitution"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="intelligence"
                    checked={intelligence}
                    onChange={handleSavingThrowsChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Intelligence"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="wisdom"
                    checked={wisdom}
                    onChange={handleSavingThrowsChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Wisdom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="charisma"
                    checked={charisma}
                    onChange={handleSavingThrowsChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Charisma"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSavingThrows}>Close</Button>
            </DialogActions>
          </Dialog>
            <InputLabel shrink id="damage-vulnerabilities-select-label" className={classes.stackedSelectInputs}>
              Damage Vulnerabilities
            </InputLabel>
            <Select
              name="damageVulnerabilities"
              labelId="damage-vulnerabilities"
              id="damage-vulnerabilities"
              value={monster.damageVulnerabilities}
              onChange={handleChange}
              label="Damage Vulnerabilities"
              fullWidth
            >
              {damages.map(vulnerability => {
                return (
                  <MenuItem key={vulnerability} value={vulnerability}>
                    {capitalize(vulnerability)}
                  </MenuItem>
                )
              })}
            </Select>
            <InputLabel shrink id="damage-immunities-select-label" className={classes.stackedSelectInputs}>
              Damage Immunities
            </InputLabel>
            <Select
              name="damageImmunities"
              labelId="damage-immunities"
              id="damage-immunities"
              value={monster.damageImmunities}
              onChange={handleChange}
              label="Damage Immunities"
              fullWidth
            >
              {damages.map(immunity => {
                return (
                  <MenuItem key={immunity} value={immunity}>
                    {capitalize(immunity)}
                  </MenuItem>
                )
              })}
            </Select>
            <InputLabel shrink id="damage-resistances-select-label" className={classes.stackedSelectInputs}>
              Damage Resistances
            </InputLabel>
            <Select
              name="damageResistances"
              labelId="damage-resistances"
              id="damage-resistances"
              value={monster.damageResistances}
              onChange={handleChange}
              label="Damage Resistances"
              fullWidth
            >
              {damages.map(resistance => {
                return (
                  <MenuItem key={resistance} value={resistance}>
                    {capitalize(resistance)}
                  </MenuItem>
                )
              })}
            </Select>
            <InputLabel shrink id="condition-immunities-select-label" className={classes.stackedSelectInputs}>
              Condition Immunities
            </InputLabel>
            <Select
              name="conditionImmunities"
              labelId="condition-immunities"
              id="condition-immunities"
              value={monster.conditionImmunities}
              onChange={handleChange}
              label="Condition Immunities"
              fullWidth
            >
              {conditions.map(immunity => {
                return (
                  <MenuItem key={immunity} value={immunity}>
                    {capitalize(immunity)}
                  </MenuItem>
                )
              })}
            </Select>
            <InputLabel shrink id="senses-select-label" className={classes.stackedSelectInputs}>
              Senses
            </InputLabel>
            <Select
              name="senses"
              labelId="senses"
              id="senses"
              value={monster.senses}
              onChange={handleChange}
              label="Senses"
              fullWidth
            >
              {senses.map(sense => {
                return (
                  <MenuItem key={sense} value={sense}>
                    {capitalize(sense)}
                  </MenuItem>
                )
              })}
            </Select>
            <InputLabel shrink id="languages-select-label" className={classes.stackedSelectInputs}>
              Languages
            </InputLabel>
            <Select
              className={classes.lastField}
              name="languages"
              labelId="languages"
              id="languages"
              value={monster.languages}
              onChange={handleChange}
              label="Languages"
              fullWidth
            >
              {languages.map(language => {
                return (
                  <MenuItem key={language} value={language}>
                    {capitalize(language)}
                  </MenuItem>
                )
              })}
            </Select>
            <Divider color="primary" />
            <TextField
              style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}
              name="specialTraits"
              id="special-traits"
              label="Special Traits"
              fullWidth
              type="text"
              multiline
              maxRows={4}
              value={monster.specialTraits}
              onChange={handleChange}
            />
            <Divider color="primary" />
            <TextField
              className={classes.firstField}
              name="actions"
              id="actions"
              label="Actions"
              fullWidth
              type="text"
              multiline
              maxRows={4}
              value={monster.actions}
              onChange={handleChange}
            />

          <Button variant="contained" color="secondary" onClick={saveNewMonster} className={classes.button}>
            Save
          </Button>    
        </div>)}
    </Container>
  );
};

export default AddMonster;
