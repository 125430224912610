import React, { useState, useEffect } from 'react';
import MonsterDataService from '../../shared/services/monster';
import { Link } from 'react-router-dom';
import { Fab, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.secondary
  },
}));

const Monster = props => {
  const initialMonsterState = {
    id: props.match.params.id
  };

  const classes = useStyles();

  const [monster, setMonster] = useState(initialMonsterState);

  const getMonster = async (id) => {
    try {
      const response = await MonsterDataService.get(id);
      setMonster(response);
    } catch (err) {
      console.log('error caught: ' + err);
    };
  };

  useEffect(() => {
    getMonster(props.match.params.id);
  }, [props.match.params.id]);

  const deleteAction = (actionId, index) => {
    MonsterDataService.deleteAction(actionId)
    .then(response => {
      setMonster((prevState) => {
        prevState.actions.splice(index, 1)
        return({
          ...prevState
        })
      })
    })
    .catch(err => {
      console.log(err);
    })
  };

  return (
    <div>
      {monster ? (
        <div>
          <h5>{monster.name}</h5>
          <p>
            <strong>Description:</strong>{monster.description}
          </p>
          <p> {JSON.stringify(monster)} </p>
        </div>
      ) : (
        <div>
          <br />
          <p>Monster failed to load.</p>
        </div>
      )}
      <div>
        <Fab className={classes.fab}
        color="secondary"
        aria-label="edit"
        component={Link}
        to={{
          pathname: `/monsters/editMonster`,
          state: monster
        }}>
          <EditIcon />
        </Fab>
      </div>
    </div>
  );
};

export default Monster;
