export const monsterSizes = ['tiny', 'small', 'medium', 'large', 'huge', 'gargantuan'];

export const monsterTypes = [
    'aberation',
    'beast',
    'celestial',
    'construct',
    'dragon',
    'elemental',
    'fey',
    'fiend - demon',
    'fiend - devil',
    'giant',
    'humanoid',
    'monstrosity',
    'ooze',
    'plant',
    'undead',
];

export const monsterAlignments = [
    'lawful - good',
    'lawful - neutral',
    'lawful - evil',
    'neutral - good',
    'neutral',
    'neutral - evil',
    'chaotic - good',
    'chaotic - neutral',
    'chaotic - evil',
];

export const damageEffects = [
    'acid',
    'bludgeoning from nonmagical',
    'cold',
    'fire',
    'force',
    'lightning',
    'necrotic',
    'piercing from nonmagical',
    'poison',
    'psychic',
    'radiant',
    'slashing from nonmagical',
    'thunder'
]

export const savThrows = [
    'checkboxes to come',
    'strength',
    'dexterity',
    'consititution',
    'intelligence',
    'wisdom',
    'charisma'
]

export const damages = [
    'acid',
    'bludgeoning from nonmagic',
    'cold',
    'fire',
    'force',
    'lightning',
    'necrotic',
    'piercing from nonmagic',
    'poison',
    'psychic',
    'radiant',
    'slashing from nonmagic',
    'thunder'
]

export const senses = [
    'drop down to come'
]

export const languages = [
    'common',
    'dwarvish',
    'elvish',
    'giant',
    'gnomish',
    'goblin',
    'halfling',
    'orc',
    'abyssal',
    'celestial',
    'draconic',
    'deep speech',
    'infernal',
    'primordial',
    'sylvan',
    'under common'
]

export const conditions = [
    'blinded',
    'charmed',
    'deafened',
    'exhausted',
    'frightened',
    'grappled',
    'incapacitated',
    'paralyzed',
    'petrified',
    'poisoned',
    'prone',
    'restrained',
    'stunned',
    'unconscious'
]